import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 4000.000000 4000.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,4000.000000) scale(0.100000,-0.100000)">
          
<path d="M15650 27499 c-308 -31 -602 -138 -865 -314 -457 -306 -759 -811
-815 -1364 -6 -67 -10 -629 -10 -1593 l0 -1489 608 587 c334 323 643 623 688
666 l80 79 -9 -73 c-17 -133 -13 -578 7 -738 25 -216 89 -504 108 -498 4 2 55
47 112 101 58 53 107 97 110 97 3 0 6 -6 6 -12 0 -21 103 -227 150 -301 l43
-68 28 27 c16 15 30 26 33 23 2 -2 25 -31 51 -64 26 -33 67 -80 91 -105 l44
-46 -35 -18 c-19 -11 -35 -22 -35 -26 0 -3 17 -23 38 -43 l38 -37 24 25 c13
14 30 25 39 25 8 0 56 20 105 45 50 24 132 57 181 72 l90 28 2698 3 c2608 2
2701 2 2790 -17 113 -23 221 -65 320 -125 l76 -46 170 0 c166 0 209 8 154 28
-27 10 -26 10 14 11 23 1 45 6 48 11 4 6 67 10 153 11 l147 0 -75 28 -75 29
181 3 c151 3 185 7 200 20 14 12 49 19 129 24 l110 7 -50 22 -49 22 112 13
c217 25 438 79 486 121 17 14 70 45 118 67 237 112 434 302 530 510 17 37 44
82 59 100 58 68 86 352 50 513 -7 29 -4 39 15 60 l22 25 -22 70 c-40 126 -113
255 -204 363 -30 36 -41 56 -33 61 8 5 3 15 -14 31 -19 18 -22 26 -13 32 15
10 -23 44 -126 115 -74 50 -216 126 -222 120 -3 -2 1 -28 8 -58 21 -89 15
-338 -10 -431 -52 -192 -127 -329 -256 -464 -139 -148 -286 -237 -484 -292
l-97 -27 -3415 0 -3415 0 -81 22 c-256 68 -469 218 -608 426 -71 107 -112 196
-144 317 -35 131 -37 361 -5 490 49 193 144 359 285 496 135 132 273 211 458
262 l95 27 1420 3 c906 1 1423 6 1429 12 7 7 -86 10 -264 10 l-274 0 29 30 29
30 -300 0 -299 1 108 104 108 105 -138 0 c-465 -1 -1111 -30 -1321 -60 -36 -5
-68 -8 -69 -6 -3 3 1579 1538 1786 1733 l66 62 -1580 -1 c-869 0 -1618 -4
-1665 -9z"/>
<path d="M22484 22261 c16 -18 29 -21 95 -20 l76 1 -55 14 c-30 7 -73 16 -95
20 l-40 6 19 -21z"/>
<path d="M22548 22208 c6 -6 18 -8 28 -6 14 3 12 5 -9 10 -19 3 -25 2 -19 -4z"/>
<path d="M22843 21638 c24 -99 16 -287 -19 -426 -102 -407 -459 -714 -887
-761 -127 -14 -5174 -15 -5297 -1 -330 38 -622 225 -777 498 l-30 53 -12 -58
c-7 -32 -15 -134 -18 -227 l-6 -170 45 37 45 37 7 -82 c7 -79 42 -278 52 -287
2 -2 12 3 22 12 17 16 19 13 40 -52 45 -139 138 -309 242 -439 l42 -54 26 25
27 25 88 -84 c48 -46 82 -84 75 -84 -7 -1 -26 -6 -42 -13 l-29 -13 37 -28 38
-28 27 26 27 26 51 -35 52 -35 1478 -2 1478 -3 85 -24 c109 -30 220 -77 293
-124 56 -36 109 -48 122 -27 3 6 -6 11 -22 11 -25 2 -25 2 7 8 41 8 106 36
115 51 3 5 48 11 98 11 64 1 83 4 62 9 -20 5 11 8 90 10 114 2 118 3 80 15
-37 13 -31 14 96 14 109 1 139 4 149 16 10 12 48 15 214 16 l201 0 -50 24 -50
23 137 1 c75 1 139 5 142 10 3 5 86 12 183 16 107 4 188 12 203 20 15 7 90 15
180 19 243 10 425 39 612 96 62 19 110 41 130 59 18 16 60 42 93 59 176 89
342 248 435 417 18 33 43 71 56 85 41 46 66 151 72 304 5 154 -6 240 -45 349
-18 50 -21 72 -14 81 13 15 -16 74 -80 168 -24 36 -35 60 -28 64 15 9 -106
131 -195 197 -58 43 -66 52 -50 58 11 4 15 8 9 8 -5 1 1 8 14 18 23 16 23 17
5 30 -10 8 -45 28 -78 45 l-59 30 6 -24z"/>
<path d="M15797 21050 c-3 -8 -8 -26 -11 -39 l-5 -24 21 20 c16 16 18 25 10
39 -9 16 -12 16 -15 4z"/>
<path d="M20483 18446 c-7 -484 -364 -896 -848 -981 -129 -22 -2938 -23 -3066
0 -132 23 -294 85 -381 146 -36 26 -38 25 -38 -21 0 -40 7 -49 26 -30 7 7 14
-19 22 -76 7 -47 24 -122 38 -166 l26 -80 22 21 22 21 37 -83 c21 -46 60 -117
87 -160 60 -93 209 -252 223 -238 7 7 19 4 37 -9 19 -13 30 -16 37 -9 8 8 29
-1 73 -31 83 -57 221 -121 354 -165 l108 -37 23 22 23 22 104 -21 c147 -30
334 -48 357 -34 12 7 96 9 273 5 186 -3 257 -2 262 7 5 7 61 11 180 11 142 0
176 3 186 15 11 13 67 15 391 15 215 0 380 4 383 9 4 5 50 12 104 15 200 13
331 28 451 52 126 25 151 34 186 64 11 9 57 32 103 50 281 111 509 333 600
583 14 40 36 84 48 99 46 56 45 296 -1 452 -14 49 -23 96 -21 103 8 20 -47
125 -106 206 -28 37 -85 100 -129 140 -55 52 -75 77 -68 84 8 8 -7 22 -51 49
-46 28 -58 40 -45 44 14 5 13 8 -7 17 l-23 10 -2 -121z"/>
<path d="M21396 13864 c-145 -28 -270 -114 -322 -221 -51 -104 -43 -256 18
-347 59 -89 155 -141 405 -216 162 -49 199 -67 241 -114 36 -42 48 -89 32
-131 -62 -164 -386 -148 -636 31 l-71 51 -51 -104 c-29 -57 -52 -107 -52 -112
0 -17 164 -119 241 -150 112 -45 183 -61 294 -68 248 -14 457 82 533 245 24
50 27 69 27 162 0 125 -16 170 -89 243 -60 60 -147 101 -353 167 -228 73 -283
114 -283 211 0 33 6 47 33 74 83 83 269 71 477 -33 52 -26 98 -48 101 -50 4
-2 68 134 107 228 4 11 -111 69 -191 96 -152 52 -322 66 -461 38z"/>
<path d="M14130 13180 l0 -690 130 0 130 0 0 270 0 270 310 0 310 0 0 115 0
115 -310 0 -310 0 0 190 0 190 345 0 345 0 0 115 0 115 -475 0 -475 0 0 -690z"/>
<path d="M15170 13181 l0 -691 130 0 130 0 0 205 0 205 183 0 182 0 115 -201
c63 -111 120 -203 125 -205 6 -3 74 -3 151 -2 l141 3 -139 210 c-76 116 -143
217 -148 226 -8 13 2 23 49 53 136 84 210 228 211 408 0 234 -117 388 -347
455 -48 14 -119 17 -420 21 l-363 4 0 -691z m723 439 c59 -21 123 -83 141
-137 46 -135 -6 -270 -126 -324 -49 -22 -68 -24 -265 -27 l-213 -4 0 257 0
257 213 -5 c142 -2 225 -8 250 -17z"/>
<path d="M16500 13180 l0 -690 130 0 130 0 0 690 0 690 -130 0 -130 0 0 -690z"/>
<path d="M17000 13180 l0 -690 515 0 515 0 0 115 0 115 -385 0 -385 0 0 175 0
175 335 0 335 0 0 115 0 115 -335 0 -335 0 0 170 0 170 375 0 375 0 0 115 0
115 -505 0 -505 0 0 -690z"/>
<path d="M18200 13180 l0 -690 130 0 130 0 0 472 0 472 352 -472 352 -472 123
0 123 0 0 690 0 690 -125 0 -125 0 -2 -475 -3 -475 -353 475 -354 475 -124 0
-124 0 0 -690z"/>
<path d="M19660 13180 l0 -692 353 5 c397 4 421 8 567 79 67 34 103 60 171
128 127 126 183 251 195 440 10 156 -30 305 -118 436 -62 92 -147 165 -253
215 -147 70 -160 72 -557 77 l-358 4 0 -692z m683 445 c167 -43 283 -161 322
-326 49 -212 -33 -421 -206 -522 -79 -46 -145 -57 -351 -57 l-188 0 0 460 0
460 183 0 c130 0 199 -4 240 -15z"/>
<path d="M22533 13863 c3 -4 123 -294 267 -643 144 -349 271 -656 282 -682 19
-45 23 -48 58 -48 l37 0 269 653 269 652 223 3 222 2 0 -655 0 -655 40 0 40 0
0 655 0 655 230 0 230 0 0 35 0 35 -517 -2 -518 -3 -257 -630 c-142 -346 -259
-634 -261 -640 -2 -5 -7 -7 -11 -4 -3 3 -122 291 -264 640 l-257 634 -44 3
c-23 2 -41 0 -38 -5z"/>
<path d="M24840 13407 c0 -429 1 -470 20 -541 49 -191 170 -313 362 -362 85
-22 253 -22 338 1 196 51 329 198 365 402 11 59 14 188 15 521 l0 442 -40 0
-40 0 0 -468 c0 -498 -2 -529 -51 -624 -27 -54 -101 -131 -153 -162 -98 -58
-287 -79 -416 -47 -169 42 -277 157 -315 336 -12 56 -15 161 -15 518 l0 447
-35 0 -35 0 0 -463z"/>

        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
